<template>
  <v-container
    fluid
    class="fill-height login_desktop"
    style="align-items: center"
  >
    <v-row
      :class="
        $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
          ? 'px-16 mb-16'
          : ''
      "
      align="center"
      justify="center"
    >
      <v-container
        fluid
        :class="
          $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
            ? 'mx-0 mb-16'
            : ''
        "
      >
        <v-card
          class="mb-16"
          flat
          style="position: relative; border-radius: 25px"
        >
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row align="center" justify="center">
                <v-col cols="12" class="mt-5" align="center">
                  <v-img
                    :src="require('@/assets/img/icon_fujifilm.png')"
                    max-height="120px"
                    max-width="250px"
                    contain
                  ></v-img>
                </v-col>

                <v-col class="mt-10" cols="12" align="center">
                  <div
                    class="black--text py-2"
                    style="
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                    "
                  >
                    หน้าต่างสำหรับการยืนยันการต่อสัญญาซอฟต์แวร์และบริการสำหรับลูกค้า
                  </div>
                  <div
                    class="black--text pt-0 pb-2"
                    style="
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                    "
                  >
                    Auto Renew confirmation page for customer feedback
                  </div>
                </v-col>

                <v-col cols="12" align="start">
                  <p
                    class="black--text mt-2"
                    style="
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                    "
                  >
                    1. กรุณายืนยันการต่อสัญญา/Please confirm your renewal
                    <span class="red--text">*</span>
                    <span class="red--text" v-if="this.status_check === true"
                      >Please fill in required fields.</span
                    >
                  </p>
                </v-col>

                <v-col cols="11" class="pt-0" align="start">
                  <v-checkbox v-model="checkbox_all" style="width: 500px">
                    <template v-slot:label>
                      <div class="black--text">
                        เลือกเพื่อต่อสัญญาทั้งหมด (Select to renew all)
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>

                <v-col
                  :cols="
                    $vuetify.breakpoint.lgOnly || $vuetify.breakpoint.xlOnly
                      ? 11
                      : 12
                  "
                >
                  <!-- Data Table -->
                  <!-- <v-simple-table v-if="item_list.length > 0"> -->
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th
                            style="border-top-left-radius: 20px; width: 20%"
                            class="text-center primary white--text subtitle-1 pt-2"
                          >
                            Software Type
                          </th>
                          <th
                            class="text-center primary white--text subtitle-1 pt-2"
                            style="width: 20%"
                          >
                            Serial
                          </th>
                          <th
                            style="border-top-right-radius: 20px"
                            class="text-center primary white--text subtitle-1 pt-2"
                          >
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in item_list" :key="index">
                          <td class="text-center">
                            {{ item.software_type ? item.software_type : "" }}
                          </td>

                          <td class="text-center">
                            {{ item.serial ? item.serial : "" }}
                          </td>

                          <td class="text-center">
                            <v-radio-group
                              v-model="item.description"
                              row
                              id="test"
                            >
                              <v-radio value="renew">
                                <template v-slot:label>
                                  <div
                                    class="black--text"
                                    style="font-size: 14px"
                                  >
                                    ต่อสัญญา (Renew)
                                  </div>
                                </template>
                              </v-radio>
                              <v-radio value="terminate">
                                <template v-slot:label>
                                  <div
                                    class="black--text"
                                    style="font-size: 14px"
                                  >
                                    ยกเลิกสัญญา (Terminate)
                                  </div>
                                </template>
                              </v-radio>

                              <v-radio value="change_request">
                                <template v-slot:label>
                                  <div
                                    class="black--text pt-4"
                                    style="font-size: 14px"
                                  >
                                    เปลี่ยนแปลงรายการหรือ
                                    <br />ขอข้อมูลเพิ่มเติม (Change Request)
                                  </div>
                                </template>
                              </v-radio>
                            </v-radio-group>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>

                <v-col cols="12" align="start">
                  <p
                    class="black--text mt-2"
                    style="
                      font-size: 16px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                    "
                  >
                    2. คำถามอื่นๆ / Other Queries
                  </p>
                </v-col>

                <v-col cols="11" align="start">
                  <v-textarea
                    v-model="other_queries"
                    :maxlength="255"
                    outlined
                    color="primary"
                    rows="3"
                    row-height="30"
                  ></v-textarea>
                </v-col>
                <v-col cols="11" align="start" style="margin-top: -50px">
                  <span
                    >ระบุได้ไม่เกิน 255 ตัวอักษร / You can specify up to 255
                    characters.</span
                  >
                </v-col>

                <v-col cols="11" class="pt-8" align="end">
                  <v-btn
                    color="primary"
                    class="white--text text-capitalize"
                    @click="submit()"
                    :loading="loading"
                    style="
                      border-radius: 10px;
                      font-size: 18px;
                      font-style: normal;
                      font-weight: 450;
                      line-height: 25px;
                      letter-spacing: 0em;
                      text-align: left;
                      height: 45px;
                    "
                  >
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </v-container>
</template>
<script>
import { rules } from "@/validate/validate";
export default {
  components: {},

  data() {
    return {
      valid: true,
      checkbox_all: false,
      status_check: false,
      loading: false,
      ticket_id: "",

      item_list: [],
      other_queries: "",
      transaction_id: "",
      password: "",
      status_login: true,

      length_list: 0,
      //rule
      empty: rules.empty,
      empty_other_queries: rules.empty_other_queries,

      timerCount: 0,
    };
  },
  watch: {
    checkbox_all(val) {
      if (val === true) {
        this.status_check = false;
        this.convertDescritionTrue();
      } else {
        let count = 0;
        for (let i = 0; i < this.item_list.length; i++) {
          if (this.item_list[i].description === "renew") {
            count = count + 1;
          }
        }

        if (count === this.length_list) {
          this.convertDescritionFalse();
        }
      }
    },

    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 500);
        }
        if (value === 0) {
          this.timerCount = this.length_list;
        }

        if (this.checkbox_all === true) {
          let count = 0;
          for (let i = 0; i < this.item_list.length; i++) {
            if (this.item_list[i].description === "renew") {
              count = count + 1;
            }
          }
          if (count !== this.length_list) {
            this.checkbox_all = false;
          }
        }
      },
      immediate: true, // This ensures the watcher is triggered upon creation
    },
  },
  computed: {},

  created() {
    this.queryParams();
    this.getTicket();
  },
  mounted() {},
  methods: {
    queryParams() {
      this.ticket_id = this.$route.params.ticket_id;
    },
    getTicket() {
      this.axios
        .get(process.env.VUE_APP_API + "ticket/" + this.ticket_id)
        .then((res) => {
          if (res.data) {
            if (res.data.massage === "ticket_id complete") {
              this.$router.push("/login");
            } else {
              this.item_list = res.data;
              this.timerCount = res.data.length;
              this.length_list = res.data.length;
            }
          }
        });
    },
    convertDescritionTrue() {
      for (let i = 0; i < this.item_list.length; i++) {
        this.item_list[i].description = "renew";
      }
    },
    convertDescritionFalse() {
      for (let i = 0; i < this.item_list.length; i++) {
        this.item_list[i].description = "pending";
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        for (let i = 0; i < this.item_list.length; i++) {
          if (this.item_list[i].description === "pending") {
            this.status_check = true;
            break;
          } else {
            this.status_check = false;
          }
        }

        if (this.status_check === false) {
          this.postAPI();
        }
      } else {
        for (let i = 0; i < this.item_list.length; i++) {
          if (this.item_list[i].description === "pending") {
            this.status_check = true;
            break;
          } else {
            this.status_check = false;
          }
        }
      }
    },

    postAPI() {
      this.loading = true;
      let body = {
        other_queries: this.other_queries,
        software: this.item_list,
      };
      this.axios
        .put(process.env.VUE_APP_API + "ticket/" + this.ticket_id, body)
        .then((res) => {
          this.loading = false;

          if (res.data.massage == "update_success") {
            this.$router.push("/thankyou");
          } else if (res.data.massage == "ticket_id is complete") {
            this.$router.push("/login");
          } else {
            this.status_login = false;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
